import { Link } from 'gatsby';
import * as React from 'react';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { Layout } from '../components/Layout';
import SEOHeader from '../components/SEOHeader';

const NotFoundPage = () => {
    return (
        <Layout>
            <SEOHeader title='Page Not Found' />
            <div className='flex flex-col w-full max-w-7xl mx-auto'>
                <Breadcrumbs
                    data={[{ title: "Home", url: "/" }, { title: "Page Not Found", url: "/404" }]}
                />
                <div className='flex flex-col items-center justify-center grow gap-4 p-2 py-8'>
                    <p className='italic'>You hear a dissonant chord from the distance...</p>
                    <Link className='text-3xl hover:underline' to="/">Return Home</Link>
                </div>
            </div>
        </Layout>
    )
};

export default NotFoundPage;
